import axios from "axios";
import authHeader from "./auth-header";

const apiClient = axios.create({
    baseURL: 'https://api.tremendo.fi' || process.env.API_URL || 'http://localhost:8000/api/',
    headers: {'Content-Type': 'application/json'}
});

apiClient.interceptors.request.use((config) => {
    config.headers = {...config.headers, ...authHeader()}
    return config
})

export default apiClient;
