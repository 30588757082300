import apiClient from "./api";
import {CustomerInput, DeliveryInput, MovieInput, ProductInput, WarehouseInvoiceInput} from "../../types/types";
import {isGuest} from "./auth.service";

class WarehouseService {
    getCustomers() {
        return apiClient.get('/api/v1/customer/')
    }

    addCustomer(customer: CustomerInput) {
        return apiClient.post('/api/v1/customer/', customer)
    }

    getMovies() {
        return apiClient.get('/api/v1/movie/')
    }

    searchMovies(name: string) {
        return apiClient.get('/api/v1/movie/?name=' + name)
    }

    async addMovie(movie: MovieInput) {
        const movieInput = {...movie}
        delete movieInput.product
        const movieResult = await apiClient.post('/api/v1/movie/', movieInput)
        const productInput = {...movie.product, movie: movieResult.data.id}
        if (!productInput.arrival_amount) {
            productInput.arrival_amount = 0
        }
        if (!productInput.current_stock) {
            if (productInput.arrival_amount) productInput.current_stock = productInput.arrival_amount
            else productInput.current_stock = 0
        }
        if (!productInput.shelving_location) {
            productInput.shelving_location = ''
        }
        const productResult = !isGuest() ? await this.addProduct((productInput as ProductInput)) : {}
        return {
            movie: movieResult,
            product: productResult
        }
    }

    async editMovie(movie: MovieInput) {
        const movieInput = {...movie}
        const movieResult = await apiClient.patch(`/api/v1/movie/${movieInput.id}/`, movieInput)
        return movieResult
    }

    async deleteMovie(movieId: number) {
        const deleteMovie = await apiClient.delete(`/api/v1/movie/${movieId}/`)
        return deleteMovie
    }

    getProducts() {
        return apiClient.get('/api/v1/product/')
    }

    addProduct(product: ProductInput) {
        if (!product.arrival_amount) {
            product.arrival_amount = 0
        }
        if (!product.current_stock) {
            if (product.arrival_amount) product.current_stock = product.arrival_amount
            else product.current_stock = 0
        }
        if (!product.shelving_location) {
            product.shelving_location = ''
        }
        return apiClient.post('/api/v1/product/', product)
    }

    async editProduct(product: ProductInput) {
        if (!product.arrival_amount) {
            product.arrival_amount = 0
        }
        if (!product.current_stock) {
            if (product.arrival_amount) product.current_stock = product.arrival_amount
            else product.current_stock = 0
        }
        if (!product.shelving_location) {
            product.shelving_location = ''
        }
        const productResult = await apiClient.patch(`/api/v1/product/${product.id}/`, product)
        return productResult
    }

    async deleteProduct(productId: number) {
        const deleteProduct = await apiClient.delete(`/api/v1/product/${productId}/`)
        return deleteProduct
    }

    getProductTypes() {
        return apiClient.get('/api/v1/product_type/')
    }

    getWarehouseInvoices() {
        return apiClient.get('/api/v1/warehouse_invoice/')
    }

    addWarehouseInvoice(warehouseInvoiceInput: WarehouseInvoiceInput) {
        return apiClient.post('api/v1/warehouse_invoice/', warehouseInvoiceInput)
    }

    getDeliveries() {
        return apiClient.get('/api/v1/delivery/')
    }

    addDelivery(delivery: DeliveryInput) {
        return apiClient.post('/api/v1/delivery/', delivery)
    }

    invoiceDelivery(deliveryId: number) {
        return apiClient.patch(`/api/v1/delivery/${deliveryId}/`, {
            invoiced: true
        })
    }
}

export default new WarehouseService()