import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal"
import format from "date-fns/format"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import WarehouseService from "services/api/warehouse.service"
import {
    Customer,
    CustomerInput,
    Delivery, DeliveryInput,
    Movie,
    MovieInput,
    Product,
    ProductInput,
    ProductType, WarehouseInvoice
} from "../../types/types";
import Form from "react-bootstrap/Form";
import fi from 'date-fns/locale/fi';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import {isGuest} from "../../services/api/auth.service";

registerLocale('fi', fi)
setDefaultLocale('fi')
type ModalState = {
    type: string;
    item: string;
    id?: number;
    customer?: number;
    movie?: number;
    product?: number;
}

const Dashboard = (props: any) => {
    const [error, setError] = useState(null)
    const [customers, setCustomers] = useState<Customer[]>([])
    const [movies, setMovies] = useState<Movie[]>([])
    const [filteredMovies, setFilteredMovies] = useState<Movie[]>([])
    const [searchedMovies, setSearchedMovies] = useState<Movie[]>([])
    const [search, setSearch] = useState('')
    const [products, setProducts] = useState<Product[]>([])
    const [productTypes, setProductTypes] = useState<ProductType[]>([])
    const [warehouseInvoices, setWarehouseInvoices] = useState<WarehouseInvoice[]>([])
    const [deliveries, setDeliveries] = useState<Delivery[]>([])
    const [modalState, setModalState] = useState<ModalState>({
        id: undefined,
        item: "",
        type: "",
        customer: undefined,
        movie: undefined,
        product: undefined
    })
    const [show, setShow] = useState(false)
    const [form, setForm] = useState<CustomerInput | MovieInput | ProductInput | DeliveryInput | Record<string, unknown>>({})
    const [guest, setGuest] = useState(false)
    const [showPast, setShowPast] = useState(false)
    const hostname = window.location.hostname

    const handleClose = () => {
        setModalState({
            type: "",
            item: "",
            id: undefined,
        })
        setForm({})
        setShow(false)
    }
    const handleShow = (type: string, item: string, id?: number, customer?: number, movie?: number, product?: number) => {
        setModalState({
            type,
            item,
            id
        })
        if (item === 'movie') {
            if (id) {
                const movie = getMovieById(id)
                setForm({
                    id: id,
                    customer: customer,
                    name: movie?.name || "",
                    premiere_date: movie?.premiere_date ? new Date(movie?.premiere_date).toISOString() : new Date().toISOString(),
                    secret: movie?.secret || ""
                })
            } else {
                setForm({
                    customer: customer,
                    premiere_date: new Date().toISOString(),
                    product: {
                        product_type: "1",
                        arrival_date: new Date().toISOString()
                    }
                })
            }
        }
        if (item === 'product') {
            if (id) {
                const product = getProductById(id)
                setForm({
                    id: id,
                    product_type: product?.product_type.id,
                    movie: product?.movie.id,
                    current_stock: product?.current_stock,
                    arrival_date: product?.arrival_date,
                    shelving_location: product?.shelving_location
                })
            } else {
                setForm({
                    product_type: "1",
                    movie: movie,
                    arrival_date: new Date().toISOString()
                })
            }
        }
        if (item === 'delivery') {
            setForm({
                product: product,
                delivery_date: new Date().toISOString(),
                to: "",
                location_amount: 0,
                amount: 0,
                invoiced: false,
            })

        }
        setShow(true)
    }

    const onChangeDateValue = (date: Date, field: string) => {
        const newForm = {...form}
        if (field === 'productarrival_date') {
            // @ts-ignore
            if (modalState.item === 'movie') newForm['product']['arrival_date'] = date.toISOString()
            // @ts-ignore
            else newForm['arrival_date'] = date.toISOString()
        } else if (field === 'delivery_date') {
            // @ts-ignore
            newForm['delivery_date'] = date.toISOString()
        } else {
            // @ts-ignore
            newForm['premiere_date'] = date.toISOString()
        }
        setForm(newForm)

    }

    const onChangeFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const newForm = {...form}
        if (modalState.item === 'movie') {
            if (e.target.id.includes('product')) {
                // @ts-ignore
                newForm['product'][e.target.id.substring(7)] = value
            } else {
                // @ts-ignore
                newForm[e.target.id] = value;
            }
            setForm(newForm)
        } else if (modalState.item === 'product') {
            // @ts-ignore
            newForm[e.target.id.substring(7)] = value
            setForm(newForm)
        } else {
            // @ts-ignore
            newForm[e.target.id] = value;
            setForm(newForm)
        }
    };

    const onFormSubmit = async () => {
        switch (modalState.item) {
            case 'customer':
                await WarehouseService.addCustomer((form as CustomerInput))
                WarehouseService.getCustomers().then(
                    response => {
                        response.data.sort(function (a: Customer, b: Customer) {
                            let nameA = a.name.toUpperCase();
                            let nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        });
                        setCustomers(response.data)
                    },
                    error => {
                        setError(error)
                    })
                handleClose()
                break
            case 'movie':
                const newMovie = form as MovieInput
                if (newMovie.id) {
                    await WarehouseService.editMovie(newMovie)
                    const movies = await WarehouseService.getMovies();
                    setMovies(movies.data)
                } else {
                    await WarehouseService.addMovie((form as MovieInput))
                    const movies = await WarehouseService.getMovies();
                    setMovies(movies.data)
                    const products = await WarehouseService.getProducts();
                    setProducts(products.data)
                }
                handleClose()
                break
            case 'product':
                const newProduct = form as ProductInput
                if (newProduct.id) {
                    await WarehouseService.editProduct(newProduct)
                    const products = await WarehouseService.getProducts();
                    setProducts(products.data)
                } else {
                    await WarehouseService.addProduct((form as ProductInput))
                    const products = await WarehouseService.getProducts();
                    setProducts(products.data)
                }
                handleClose()
                break
            case 'delivery':
                await WarehouseService.addDelivery((form as DeliveryInput))
                const deliveries = await WarehouseService.getDeliveries();
                setDeliveries(deliveries.data)
                const products = await WarehouseService.getProducts();
                setProducts(products.data)
                handleClose()
                break
        }
    }
    useEffect(() => {
        setGuest(isGuest())
        WarehouseService.getCustomers().then(
            response => {
                response.data.sort(function (a: Customer, b: Customer) {
                    let nameA = a.name.toUpperCase();
                    let nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                setCustomers(response.data)
            },
            error => {
                setError(error)
            })
        WarehouseService.getMovies().then(
            response => {
                setMovies(response.data)
            },
            error => {
                setError(error)
            })
        WarehouseService.getProducts().then(
            response => {
                setProducts(response.data)
            },
            error => {
                setError(error)
            })
        WarehouseService.getProductTypes().then(
            response => {
                setProductTypes(response.data)
            },
            error => {
                setError(error)
            })
        WarehouseService.getWarehouseInvoices().then(
                    response => {
                        setWarehouseInvoices(response.data)
                    },
                    error => {
                        setError(error)
                    })
        WarehouseService.getDeliveries().then(
            response => {
                setDeliveries(response.data)
            },
            error => {
                setError(error)
            })
    }, [])

    const getMovieById = (movieId: number) => {
        return movies.find((movie) => {
            return movie.id === movieId
        })
    }

    const getMoviesByCustomer = (customerId: number) => {
        const movies = filteredMovies.filter((movie) => {
            return movie.customer === customerId
        })
        return movies.sort(function (a: Movie, b: Movie) {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        })
    }

    const getProductsByMovie = useCallback((movieId: number) => {
        return products.filter((product) => {
            return product.movie.id === movieId
        })
    }, [products])

    const getProductById = (productId: number) => {
        return products.find((product) => {
            return product.id === productId
        })
    }
    const getProductTypeById = (productTypeId: number) => {
        return productTypes.find((productType) => {
            return productType.id === productTypeId
        })
    }

    const getWarehouseInvoicesByProductId = (productId: number) => {
        return warehouseInvoices.filter((invoice) => {
            return invoice.product.id === productId
        })
    }

    const getLatestWarehouseInvoiceDate = (productId: number) => {
        const invoices = getWarehouseInvoicesByProductId(productId)
        invoices.sort(function(a,b) {
            const aDate = new Date(a.date).valueOf();
            const bDate = new Date(b.date).valueOf();
            return bDate - aDate;
        });
        return invoices[0] ? invoices[0].date : null
    }

    const getSecondLatestWarehouseInvoiceDate = (productId: number) => {
        const invoices = getWarehouseInvoicesByProductId(productId)
        invoices.sort(function(a,b) {
            const aDate = new Date(a.date).valueOf();
            const bDate = new Date(b.date).valueOf();
            return bDate - aDate;
        });
        return invoices[1] ? invoices[1].date : null
    }

    const getDeliveriesByProductId = useCallback((productId: number) => {
        return deliveries.filter((delivery) => {
            return delivery.product === productId
        })
    }, [deliveries])

    const getDeliveryById = (deliveryId: number) => {
        return deliveries.find((delivery) => {
            return delivery.id === deliveryId
        })
    }

    const onInvoiceDelivery = async (deliveryId: number) => {
        await WarehouseService.invoiceDelivery(deliveryId)
        const newDeliveries = await WarehouseService.getDeliveries()
        setDeliveries(newDeliveries.data)
    }

    const onWarehouseInvoice = async (productId: number) => {
        await WarehouseService.addWarehouseInvoice({product: productId, date: new Date().toISOString()})
        const newWarehouseInvoices = await WarehouseService.getWarehouseInvoices()
        setWarehouseInvoices(newWarehouseInvoices.data)
    }

    const deleteProduct = async (productId: number) => {
        await WarehouseService.deleteProduct(productId)
        const products = await WarehouseService.getProducts()
        setProducts(products.data)
        handleClose()
    }

    const deleteMovie = async (movieId: number) => {
        await WarehouseService.deleteMovie(movieId)
        const movies = await WarehouseService.getMovies()
        setMovies(movies.data)
        handleClose()
    }

    const isDeliveriesInvoiced = useCallback((productId: number) => {
        const deliveries = getDeliveriesByProductId(productId)
        if (deliveries.length === 0) return false;
        const notInvoiced = deliveries.filter((delivery) => {
            return !delivery.invoiced
        })
        return notInvoiced.length === 0;
    }, [getDeliveriesByProductId])

    const searchMovies = async (query: string) => {
        setSearch(query)
        if (query.length < 2) {
            setSearchedMovies([])
            return
        }
        const res = await WarehouseService.searchMovies(query)
        setSearchedMovies(res.data)
    }

    useEffect(() => {
        const searched: Movie[] = movies.filter(movie => {
            if (search.length >= 2) {
                return searchedMovies.findIndex(result => {
                    return result.id === movie.id
                }) !== -1
            } else return search.length < 2;
        })
        if (showPast) {
            setFilteredMovies(searched)
        } else {
            const filteredMovies = searched.filter((movie: Movie) => {
                const products = getProductsByMovie(movie.id)
                if (products.length === 0) return true
                const filteredProducts = products.filter((product) => {
                    if (isDeliveriesInvoiced(product.id) && product.current_stock === 0) {
                        return false;
                    } else {
                        return true;
                    }
                })
                return filteredProducts.length > 0
            })
            setFilteredMovies(filteredMovies)
        }
    }, [form, showPast, search, movies, products, searchedMovies, getProductsByMovie, isDeliveriesInvoiced])


    const renderModal = () => {
        const {type, item, id} = modalState
        let editHeader: string = ''
        let itemObject: Movie | Product | Delivery | undefined = undefined
        if (type === 'edit' && id) {
            switch (item) {
                case 'movie':
                    itemObject = getMovieById(id)
                    editHeader = itemObject ? itemObject.name : ''
                    break
                case 'product':
                    itemObject = getProductById(id)
                    editHeader = itemObject ? getMovieById(itemObject.movie.id)?.name + ' ' + getProductTypeById(itemObject.product_type.id)?.name : ''
                    break
                case 'delivery':
                    itemObject = getDeliveryById(id)
                    //editHeader = itemObject ? getMovieById(itemObject.movie)?.name + ' ' + getProductTypeById(itemObject.product_type)?.name + ' toimitus' : ''
                    break
            }
        }
        const renderHeader = (header: string) =>
            <Modal.Header>{type === 'edit' ? editHeader : `Lisää ${header}`}</Modal.Header>
        const renderFooter = (id?: number) =>
            <Modal.Footer>
                {id ? <Button variant="danger" onClick={() => modalState.item === 'product' ? deleteProduct(id) : deleteMovie(id)}>Poista</Button> : null}
                <Button variant="secondary" onClick={handleClose}>Sulje</Button>
                <Button variant="primary" onClick={onFormSubmit}>{type === 'edit' ? 'Tallenna' : 'Lisää'}</Button>
            </Modal.Footer>

        const renderProductForm = () => {
            let editing = false
            if (form.id) {
                editing = true
            }
            return (
                <>
                    <Form.Group>
                        <Form.Label>Tuote</Form.Label>
                        <Form.Control value={editing ? (form as ProductInput).product_type : (form as MovieInput).product?.product_type} as="select" id="productproduct_type" onChange={onChangeFormValue}>
                            {productTypes.map((productType) => {
                                return <option value={productType.id}>{productType.name}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tuotteen saapumispäivä</Form.Label>
                        {modalState.item === 'movie' ?
                            <ReactDatePicker dateFormat="dd.MM.yyyy"
                                             selected={new Date((form as MovieInput).product?.arrival_date || "")}
                                             onChange={(date: Date) => onChangeDateValue(date, 'productarrival_date')}/>
                            :
                            <ReactDatePicker dateFormat="dd.MM.yyyy"
                                             selected={new Date((form as ProductInput).arrival_date || "")}
                                             onChange={(date: Date) => onChangeDateValue(date, 'productarrival_date')}/>
                        }
                    </Form.Group>

                    {!editing ? <Form.Group>
                        <Form.Label>Montako tuotetta tuli?</Form.Label>
                        <Form.Control id="productarrival_amount" placeholder="Määrä" onChange={onChangeFormValue}/>
                    </Form.Group> : null }


                    <Form.Group>
                        <Form.Label>Tämän hetkinen varastosaldo</Form.Label>
                        <Form.Control value={editing ? (form as ProductInput).current_stock : (form as MovieInput).product?.current_stock} id="productcurrent_stock" placeholder="Saldo" onChange={onChangeFormValue}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Varastosijainti</Form.Label>
                        <Form.Control value={editing ? (form as ProductInput).shelving_location : (form as MovieInput).product?.shelving_location} id="productshelving_location" placeholder="Sijainti"
                                      onChange={onChangeFormValue}/>
                    </Form.Group>
                </>
            )
        }
        switch (item) {
            case 'customer':
                return (
                    <>
                        {renderHeader("asiakas")}
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Asiakkaan nimi</Form.Label>
                                    <Form.Control id="name" placeholder="nimi" onChange={onChangeFormValue}/>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        {renderFooter()}
                    </>
                )
            case 'movie':
                const movieInput = form as MovieInput
                return (
                    <>
                        {renderHeader("elokuva")}
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Elokuvan nimi</Form.Label>
                                    <Form.Control value={movieInput.name ?? ""} id="name" placeholder="nimi" onChange={onChangeFormValue}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Ensi-ilta</Form.Label>
                                    { // @ts-ignore
                                        <ReactDatePicker dateFormat="dd.MM.yyyy"
                                                         selected={new Date((form as MovieInput).premiere_date)}
                                                         onChange={(date: Date) => {
                                                             onChangeDateValue(date, 'premiere_date')
                                                         }}/>
                                    }
                                </Form.Group>


                                <Form.Group>
                                    <Form.Label>ID</Form.Label>
                                    <Form.Control value={movieInput.secret ?? ""} id="secret" placeholder="ID" onChange={onChangeFormValue}/>
                                </Form.Group>
                                {!form.id ?
                                    <div>
                                    Tuotetiedot:
                                        {renderProductForm()}</div> : null}
                            </Form>
                        </Modal.Body>
                        {renderFooter(id)}
                    </>
                )
            case 'product':
                return (
                    <>
                        {renderHeader("tuote")}
                        <Modal.Body>
                            <Form>
                                {renderProductForm()}
                            </Form>
                        </Modal.Body>
                        {renderFooter(id)}
                    </>
                )
            case 'delivery':
                return (
                    <>
                        {renderHeader("toimitus")}
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Lähetyspäivämäärä</Form.Label><br/>
                                    { // @ts-ignore
                                        <ReactDatePicker dateFormat="dd.MM.yyyy"
                                                         selected={new Date((form as DeliveryInput).delivery_date)}
                                                         onChange={(date: Date) => {
                                                             onChangeDateValue(date, 'delivery_date')
                                                         }}/>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Minne</Form.Label>
                                    <Form.Control id="to" placeholder="Minne" onChange={onChangeFormValue}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Paikkojen määrä</Form.Label>
                                    <Form.Control id="location_amount" placeholder="Paikkojen määrä"
                                                  onChange={onChangeFormValue}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Määrä</Form.Label>
                                    <Form.Control id="amount" placeholder="Määrä" onChange={onChangeFormValue}/>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        {renderFooter()}
                    </>
                )
        }
    }
    return (
        <Container fluid>
            {error ? <div>{error}</div> : null}
            <Modal show={show} onHide={handleClose}>
                {renderModal()}
            </Modal>
            {!guest ? <Row>
                <Col xs={12} className="mb-4 d-flex xs-12 justify-content-end">
                    <Form.Group>
                        <Form.Control onChange={(e) => searchMovies(e.target.value)} type="email" placeholder="Etsi elokuvaa" />
                    </Form.Group>
                    <Button onClick={() => setShowPast(!showPast)}>{showPast ? 'Piilota' : 'Näytä'} menneet</Button>
                    <Button onClick={() => handleShow('add', 'customer')}>Uusi asiakas</Button>
                </Col>
            </Row> : null}
            {hostname === 'tilaus.tremendo.fi' ? <Row><Col>Testing</Col></Row> : null}
            {customers && customers.map((customer) => {
                if (search !== '' && getMoviesByCustomer(customer.id).length === 0) {
                    return null;
                }
                return <Row key={customer.name} className="mb-3">
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Accordion.Toggle as={Col} eventKey="0">
                                            {customer.name}
                                        </Accordion.Toggle>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} className="mb-4 d-flex xs-12 justify-content-end">
                                                <Button
                                                    onClick={() => handleShow('add', 'movie', undefined, customer.id)}>Uusi
                                                    elokuva</Button>
                                            </Col>
                                        </Row>
                                        { /* TODO: Sorting,  */}
                                        <Table responsive>
                                            <thead>
                                            <tr>
                                                <th>
                                                    ID
                                                </th>
                                                <th>
                                                    Nimi
                                                </th>
                                                {!guest ? <th>
                                                    Varasto paikka
                                                </th> : null}
                                                <th>
                                                    Ensi-ilta
                                                </th>
                                                <th>
                                                    Saapunut
                                                </th>
                                                <th>
                                                    Tyyppi
                                                </th>
                                                <th>
                                                    Saldo
                                                </th>
                                                {!guest ? <th>
                                                    Päivät varastolla
                                                </th> : null }
                                                {!guest ? <th>
                                                    Jako päivät ja määrät
                                                </th> : null}
                                                {!guest ? <th>
                                                    Muokkaus
                                                </th> : null}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {getMoviesByCustomer(customer.id).map((movie) => {
                                                const products = getProductsByMovie(movie.id)
                                                let shelvingLocations: ReactElement[] = []
                                                let arrivalDates: ReactElement[] = []
                                                let productTypes: ReactElement[] = []
                                                let currentStocks: ReactElement[] = []
                                                let warehouseDaysJSX: ReactElement[] = []
                                                let deliveries: ReactElement[] = []
                                                let editButtons: ReactElement[] = []
                                                products.forEach((product) => {
                                                    let deliveriesMap = getDeliveriesByProductId(product.id).map((delivery) => {
                                                        return <div>{delivery.location_amount} ({delivery.to}): {delivery.amount}kpl
                                                            ({format(new Date(delivery.delivery_date), 'dd.MM.yyyy')}){!delivery.invoiced ?
                                                                <Button variant="link" style={{padding: 0}}
                                                                        onClick={() => onInvoiceDelivery(delivery.id)}>✅</Button>
                                                                : '❌'}</div>
                                                    })
                                                    let warehouseInvoiceDate: string | null | Date = getLatestWarehouseInvoiceDate(product.id)
                                                    let warehouseSecondInvoiceDate: string | null | Date = getSecondLatestWarehouseInvoiceDate(product.id)
                                                    let warehouseInvoiceDate2: Date = warehouseInvoiceDate ? new Date(warehouseInvoiceDate) : new Date(product.arrival_date)
                                                    const warehouseDays = product.current_stock === 0 ? 0
                                                        : warehouseInvoiceDate
                                                            ? differenceInCalendarDays(new Date(), warehouseInvoiceDate2)
                                                            : ((differenceInCalendarDays(new Date(), warehouseInvoiceDate2)) - 60) > 0
                                                                ? (differenceInCalendarDays(new Date(), warehouseInvoiceDate2)) - 60
                                                                : 0
                                                    let warehouseDaysBefore = warehouseInvoiceDate2 ?
                                                        warehouseSecondInvoiceDate ?
                                                            <div>
                                                                {format(new Date(warehouseInvoiceDate2), 'dd.MM.yyyy')}:
                                                                {differenceInCalendarDays(warehouseInvoiceDate2, new Date(warehouseSecondInvoiceDate))}
                                                            </div> :
                                                            <div>
                                                                {format(new Date(warehouseInvoiceDate2), 'dd.MM.yyyy')}:
                                                                {differenceInCalendarDays(warehouseInvoiceDate2, new Date(product.arrival_date))}
                                                            </div> : null
                                                    shelvingLocations.push(<div className="text-td">{product.shelving_location}</div>)
                                                    arrivalDates.push(<div className="text-td">{format(new Date(product.arrival_date), 'dd.MM.yyyy')}</div>)
                                                    productTypes.push(<div className="text-td">{product.product_type.name}</div>)
                                                    currentStocks.push(<div className="text-td">{product.current_stock}</div>)
                                                    warehouseDaysJSX.push(<div
                                                        style={{color: warehouseDays > 60 ? 'red' : 'black'}} className="text-td">{warehouseDays} <Button variant="link" style={{padding: 0}}
                                                                                                                                                          onClick={() => onWarehouseInvoice(product.id)}>✅</Button><br />{warehouseDaysBefore}</div>)
                                                    deliveries.push(<>{deliveriesMap}</>, <div><Button size="sm"
                                                        onClick={() => handleShow('add', 'delivery', undefined, undefined, undefined, product.id)}>Jaa</Button></div>)
                                                    editButtons.push( <div><Button size="sm" className="mr-2"
                                                                                   onClick={() => handleShow('edit', 'product', product.id)}>Muokkaa</Button></div>)
                                                })
                                                if (products.length > 0) {
                                                    return (
                                                        <tr>
                                                            <td>{movie.secret}{movie.secret ? <br /> : null}<Button style={{paddingLeft: 0, paddingTop: 0}} size="sm" variant="link" onClick={() => handleShow('edit', 'movie', movie.id, customer.id)}>Muokkaa</Button></td>
                                                            <td>{movie.name}</td>
                                                            {!guest ? <td>{shelvingLocations}</td> : null}
                                                            <td><div className="text-td">{format(new Date(movie.premiere_date), 'dd.MM.yyyy')}</div></td>
                                                            <td>{arrivalDates}</td>
                                                            <td>{productTypes}</td>
                                                            <td>{currentStocks}</td>
                                                            {!guest ? <td><div>{warehouseDaysJSX}</div></td> : null }
                                                            {!guest ? <td>{deliveries}</td> : null}
                                                            {!guest ? <td>
                                                                {editButtons}
                                                                <Button size="sm"
                                                                    onClick={() => handleShow('add', 'product', undefined, undefined, movie.id)}>Uusi
                                                                    tuote</Button>
                                                            </td> : null}
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr key={movie.id + movie.name}>
                                                            <td>{movie.secret}{movie.secret ? <br /> : null}<Button style={{paddingLeft: 0, paddingTop: 0}} size="sm" variant="link" onClick={() => handleShow('edit', 'movie', movie.id, customer.id)}>Muokkaa</Button></td>
                                                            <td>{movie.name}</td>
                                                            <td>{format(new Date(movie.premiere_date), 'dd.MM.yyyy')}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            {!guest ? <td>
                                                                <Button
                                                                    size="sm"
                                                                    onClick={() => handleShow('add', 'product', undefined, undefined, movie.id)}>Uusi
                                                                    tuote</Button>
                                                            </td> : null}
                                                        </tr>
                                                    )
                                                }
                                            })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            })}
        </Container>
    )
}

export default Dashboard;
